import React, { Component } from 'react'
import MainMint from './MainMint'
import './index.css'

let sideboob = require('./img/Web-side-boob.png')
let boobGif = require('./img/PST-GIF.gif')
let logo = require('./img/Web-Concept-1.png')

class Home extends Component {
  render () {
    return (
      <div width='100%'>
        <img className='main-image debug' src={logo.default} alt='not found' />
        <div className='main-info debug transparent-container'>
          <div className='main-text'>
            <img
              className='debug'
              // className='main-text'
              id='boobGif'
              width='100%'
              height='100%'
              src={boobGif.default}
              alt='not found'
            />
            <p className="titty-text">
              <b>Pumpkin Spice Titties</b> is a collection of 6,969 uniquely
              generated titties with a love for pumpkin spice. Although it looks
              like we're just about Titties up in here, these are your one-way
              ticket to our TittyDAO.
            </p>
            {/* <div className='mint-text debug'> */}
            <MainMint></MainMint>
          {/* </div> */}
          </div>
        </div>
        <div align='center'>
          <h1 id='tagline'>
            "Drink water, suck titties" <br /> - Dr. Seuss
          </h1>
        </div>
        <div className='charity-div debug'>
          <div className='charity-text debug'>
            <div className='dao-info'>
              <h1>Okay, what's really going on here?</h1>
              <p />
              <h2>
                <u>Charity Benefits:</u>
              </h2>
              <p>
                10% of all primary mint sales and 25% of all secondary sales
                fees will be going to various breast cancer charities.
              </p>
              <p />
              <h2>
                <u>TittyDAO</u>
              </h2>
              <p>
                The TittyDao is where every Pumpkin Spice Titty is equal. 25% of
                secondary sales fees will go to the TittyDAO.
              </p>
            </div>
          </div>
          <img
            className='debug'
            id='sideboob-right'
            src={sideboob.default}
            alt='not found'
          />
        </div>
        <div>
          <div align='center'>
            <h1 id='tagline'>
              "Breast cancer sucks." <br /> - Ghandi
            </h1>
          </div>
          <div className='charity-div debug'>
            <img
              className='debug'
              id='sideboob-left'
              src={sideboob.default}
              alt='not found'
            />
            <div className='charity-text debug'>
              <div className='dao-info'>
                <h1>Roadmap:</h1>
                <p />
                <h2>
                  <u>Phase 1:</u>
                </h2>
                <li>Mint Pumpkin Spice Titties</li>
                <li>
                  10% of mint sales and 25% of all secondary sales fees will be
                  donated to varioius charities focused on breast cancer.
                </li>
                <li>Form TittyDAO</li>
                <li>First NFT airdrop for Titty holders</li>
                <p />
                <h2>
                  <u>Phase 2:</u>
                </h2>
                <li>$PST token created for Titty holders</li>
                <li>Titty Shooter arcade game</li>
                <li>P2E Mechanics</li>
                <p />
                <h2>
                  <u>Phase 3:</u>
                </h2>
                <li> $PST Arcade release</li>
              </div>
            </div>
          </div>
          <div align='center'>
            <h1 id='tagline'>
              "You might not be able to touch 'em, but they sure do look sweet."{' '}
              <br />- Some guy named Paul from Philadelphia
            </h1>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
