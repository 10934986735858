import { useEffect, useState } from 'react'
import {
  connectWallet,
  getCurrentWalletConnected,
} from './utils/interact.js'

import Web3 from 'web3'
import './App.css'
import './index.css'

const MainMint = props => {

  //State variables
  const [walletAddress, setWallet] = useState('')
  const [status, setStatus] = useState('')
  const [MintMessage, setMintMessage] = useState('')
  const web3Helper = require('web3-abi-helper').Web3Helper

  let address = '0xa375dbe6ae55b0e2ac3dcde9d48bc8f1c8dc5367'

  useEffect(() => {
    async function fetchData () {
      const { address, status } = await getCurrentWalletConnected()
        setWallet(address)
        setStatus(status)
        addWalletListener()
      
    }
    fetchData()
  }, [])

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet()
    setStatus(walletResponse.status)
    setWallet(walletResponse.address)
  }

  async function mintNFT (numOfMints) {

    let method = {
      name: 'mint',
      type: 'function',
      inputs: [
        {
          type: 'uint256',
          name: '_mintValue'
        }
      ]
    }

    var params = [numOfMints]
    var encodedArguments = web3Helper.encodeMethod(method, params)
    // > "0x24ee0097000000000000000000000000000000000000000000000000000000008bd02b7b0000000000000000000000000000000000000000000000000000000000000040000000000000000000000000000000000000000000000000000000000000000748656c6c6f212500000000000000000000000000000000000000000000000000"
    console.log(encodedArguments)

    var mintGas = parseInt(150382 * 1.15)
    var gasLimit
    var mintValue

    if (numOfMints === 1) {
      console.log('state: 1')
      // var mintValue = Web3.utils.toHex(new BigNumber(69000000000000000 * 1))
      mintValue = '0xf5232269808000'
      console.log(mintValue)
      gasLimit = mintGas * 1
    } else if (numOfMints === 2) {
      console.log('state: 2')
      // mintValue = Web3.utils.toHex(new BigNumber(69000000000000000 * 2))
      mintValue = '0x1ea4644d3010000'
      console.log(mintValue)
      gasLimit = mintGas * 2
    } else if (numOfMints === 3) {
      console.log('state: 3')
      // mintValue = Web3.utils.toHex(new BigNumber(69000000000000000 * 3))
      mintValue = '0x2df69673c818000'
      console.log(mintValue)
      gasLimit = mintGas * 3
    } else if (numOfMints === 4) {
      console.log('state: 4')
      // mintValue = Web3.utils.toHex(new BigNumber(69000000000000000 * 4))
      mintValue = '0x3d48c89a6020000'
      console.log(mintValue)
      gasLimit = mintGas * 4
    } else if (numOfMints === 5) {
      console.log('state: 5')
      // mintValue = Web3.utils.toHex(new BigNumber(69000000000000000 * 5))
      mintValue = '0x4c9afac0f828000'
      console.log(mintValue)
      gasLimit = mintGas * 5
    } else if (numOfMints === 6) {
      console.log('state: 6')
      // mintValue = Web3.utils.toHex(new BigNumber(69000000000000000 * 6))
      mintValue = '0x5bed2ce79030000'
      console.log(mintValue)
      gasLimit = mintGas * 6
    } else if (numOfMints === 7) {
      console.log('state: 7')
      // mintValue = Web3.utils.toHex(new BigNumber(69000000000000000 * 7))
      mintValue = '0x6b3f5f0e2838000'
      console.log(mintValue)
      gasLimit = mintGas * 7
    } else if (numOfMints === 69) {
      console.log('state: 69')
      // mintValue = Web3.utils.toHex(new BigNumber(69000000000000000 * 69))
      mintValue = '0x421278466fa28000'
      console.log(mintValue)
      gasLimit = mintGas * 69
    }

    const transactionParameters = {
      to: address, // Required except during contract publications.
      from: window.ethereum.selectedAddress, // must match user's active address.
      gas: Web3.utils.toHex(gasLimit), // customizable by user during MetaMask confirmation.
      data: encodedArguments,
      value: mintValue
    }
    // console.log("v", valueInWei.toString())
    //sign transaction via Metamask
    try {
      const txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
      })
      return {
        success: true,
        status:
          '✅ Check out your transaction on Etherscan: https://etherscan.io/tx/' +
          txHash
      }
    } catch (error) {
      return {
        success: false,
        status: '😥 Something went wrong: ' + error.message
      }
    }
  }

  const onMint = async () => {
    var strNumOfMints = document.getElementById('mintInputField').value

    var numOfMints = parseInt(strNumOfMints, 10)
    if (typeof numOfMints !== 'number') {
      setMintMessage('Please enter a whole number. i.e: 6')
      return
    } else {
      setMintMessage('')
    }

    if (numOfMints <= 7 || numOfMints === 69) {
      setMintMessage('')
    } else {
      setMintMessage("Max number of mints is 7, unless you're freaky.")
      return
    }

    const { success, status } = await mintNFT(numOfMints)
    setStatus(status)
    if (success) {
      console.log('success')
    }
  }

  function addWalletListener () {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', accounts => {
        if (accounts.length > 0) {
          setWallet(accounts[0])
        } else {
          setWallet('')
          setStatus('🦊')
        }
      })
    } else {
      setStatus(
        <p>
          {' '}
          🦊{' '}
          <a target='_blank' href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      )
    }
  }

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 3
      }}
    />
  )
  return (
        <div class="buttons debug">
            <form id='mintInput debug'>
              <label>
                Number to mint: (max 7):
                <br />
                <input
                  id='mintInputField'
                  type='username'
                  name='name'
                  min='0'
                  defaultValue='69'
                />
                <p id='status'>{MintMessage}</p>
              </label>
            </form>
          <button id='button' onClick={onMint}>
            Mint your titties
          </button>
          <ColoredLine color='#FCE572' />
          <button id='button' onClick={connectWalletPressed}>
            {walletAddress.length > 0 ? (
              'Connected: ' +
              String(walletAddress).substring(0, 6) +
              '...' +
              String(walletAddress).substring(38)
            ) : (
              <span>Connect Wallet</span>
            )}
          </button>
          <ColoredLine color='#FCE572' />
          <p id='status'>{status}</p>
          </div>
  )
}

export default MainMint
