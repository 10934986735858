import React, { Component } from "react";
import {
    Route,
    NavLink,
    HashRouter
  } from "react-router-dom";
import App from "./App";
import Home from "./Home";

const mintOrPresale = "mint";

class Main extends Component {
  render() {
    return (
        <HashRouter>
        <div>
          <ul className="header debug">
          <div>
          <li><NavLink to="/">Home</NavLink></li>
          {/* <li><NavLink to="/presale">Presale</NavLink></li> */}
          <li><NavLink to="twitter">Twitter</NavLink></li>
          <li><NavLink to="discord">Discord</NavLink></li>
          
          </div>
          </ul>
          <div className="content">
            <Route exact path="/" component={Home}/>
            {/* <Route path="/presale" component={App}/> */}
            <Route path='/discord' component={() => { 
     window.location.href = 'https://discord.com/invite/U9VXzrVSFm'; 
     return null;
}}/>
            <Route path='/twitter' component={() => { 
     window.location.href = 'https://twitter.com/PumpkinSpiceNFT'; 
     return null;
}}/>
          </div>
          <div>
            <p className="header hidden"></p>
          </div>
        </div>
        </HashRouter>
    );
  }
}
 
export default Main;