import React, { Component } from "react";
import './App.css';

import PresaleRegistration from './PresaleRegistration'
import PresaleMint from './PresaleMint'

class App extends Component {
  render() {
    return (
      <div className="App">
        {/* <PresaleRegistration></PresaleRegistration> */}
        <PresaleMint></PresaleMint>
      </div>
    );
  }
}

export default App;
