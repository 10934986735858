import Web3 from 'web3'

const address = '0xbef33d90d2da013245b37c049b86befd78ef4ebd'

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      const obj = {
        status:
          '',
        address: addressArray[0]
      }
      return obj
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message
      }
    }
  } else {
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            <a target='_blank' href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      )
    }
  }
}

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts'
      })
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: ' '
        }
      } else {
        return {
          address: '',
          status: '🦊 Connect to Metamask using the button above.'
        }
      }
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message
      }
    }
  } else {
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            <a target='_blank' href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      )
    }
  }
}

export const registerForPresale = async data => {
  //set up your Ethereum transaction
  const transactionParameters = {
    to: address, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    gas: '0x5208', // customizable by user during MetaMask confirmation.
    data: data
  }

  //sign transaction via Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    })
    return {
      success: true,
      status:
        '✅ Check out your transaction on Etherscan: https://etherscan.io/tx/' +
        txHash
    }
  } catch (error) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message
    }
  }
}

// export const mintNFT = async numOfMint => {
//   //set up your Ethereum transaction
//   console.log("mint num:",numOfMint)
//   // const encodedArguments = Web3.eth.abi.encodeFunctionCall(
//   //   {
//   //     name: 'myMethod',
//   //     type: 'function',
//   //     inputs: [
//   //       {
//   //         type: 'uint256',
//   //         name: '_mintAmount'
//   //       }
//   //     ]
//   //   },
//   //   [numOfMint]
//   // )
//   // // > "0x24ee0097000000000000000000000000000000000000000000000000000000008bd02b7b0000000000000000000000000000000000000000000000000000000000000040000000000000000000000000000000000000000000000000000000000000000748656c6c6f212500000000000000000000000000000000000000000000000000"
//   // console.log(encodedArguments)

//   // const transactionParameters = {
//   //   to: address, // Required except during contract publications.
//   //   from: window.ethereum.selectedAddress, // must match user's active address.
//   //   gas: Web3.toHex(Web3.toWei('20', 'gwei')), // customizable by user during MetaMask confirmation.
//   //   data: encodedArguments
//   // }

//   // //sign transaction via Metamask
//   // try {
//   //   const txHash = await window.ethereum.request({
//   //     method: 'eth_sendTransaction',
//   //     params: [transactionParameters]
//   //   })
//   //   return {
//   //     success: true,
//   //     status:
//   //       '✅ Check out your transaction on Etherscan: https://etherscan.io/tx/' +
//   //       txHash
//   //   }
//   // } catch (error) {
//   //   return {
//   //     success: false,
//   //     status: '😥 Something went wrong: ' + error.message
//   //   }
//   // }
//       return {
//         success: true,
//         status:"nice"
//       }
// }
